/* 大会介绍 */
import React, { Component } from 'react'
import { withRouter } from 'react-router';
// import a6 from '../../assets/img/a6.jpg'
import './Conference.scss'
import Footer from '../../component/footer/Footer';
// import Menus from '../../component/conference/menus'
// import ConferenceRoutes from '../../component/conference/conferenceRoutes'
import wenta from '../../assets/image/wentas.svg'
import SubForum from '../../component/conference/SubForum';
import convention from '../../assets/mp4/convention.mp4'
import { Link, NavLink } from 'react-router-dom';
// import jiaoben from '../../assets/index.html'
// var htmlDoc = {__html: jiaoben};
class Conference extends Component {
  plenary(){
    document.getElementById('plenarys').style.display = 'inline'
    document.getElementById('plenary').style.background = ' linear-gradient(90deg,#00b1ff, #1b6bdb)'
    document.getElementById('plenary').style.color = '#fff'
    document.getElementById('forum').style.background = '#fff'
    document.getElementById('forum').style.color = '#0068b7'
    document.getElementById('forums').style.display = 'none'
    document.getElementById('activity').style.background = '#fff'
    document.getElementById('activity').style.color = '#0068b7'
    document.getElementById('activitys').style.display = 'none'
      
  }
  forum(){
    document.getElementById('plenarys').style.display = 'none'
    document.getElementById('plenary').style.background = '#fff'
    document.getElementById('plenary').style.color = '#0068b7'
    document.getElementById('forums').style.display = 'inline'
    document.getElementById('forum').style.background = ' linear-gradient(90deg,#00b1ff, #1b6bdb)'
    document.getElementById('forum').style.color = '#fff'
    document.getElementById('activity').style.background = '#fff'
    document.getElementById('activity').style.color = '#0068b7'
    document.getElementById('activitys').style.display = 'none'
  }
  activity(){
    document.getElementById('plenarys').style.display = 'none'
    document.getElementById('plenary').style.background = '#fff'
    document.getElementById('plenary').style.color = '#0068b7'
    document.getElementById('forums').style.display = 'none'
    document.getElementById('forum').style.background = '#fff'
    document.getElementById('forum').style.color = '#0068b7'
    document.getElementById('activitys').style.display = 'inline'
    document.getElementById('activity').style.background = ' linear-gradient(90deg,#00b1ff, #1b6bdb)'
    document.getElementById('activity').style.color = '#fff'

  }
  render() {
   
    return (
      <div>
        <div>
        <video muted src={convention}  width="100%" autoPlay loop></video>
         
        </div>
        <div className='conference'>

          <div className='conference-content'>


            <div className='conference-top'>
              <div className='conference-top-left'>
                <h2><Link activeClassName="light" to='/home'>首页</Link>><span>大会介绍</span></h2>
                <div>
                  <p>2022生命电子学术年会 整体议程</p>
                  <table cellpadding="0" cellspacing="0">
                    <tr style={{background:'#dbeaf5'}}>
                      <td className='td-center'>时间</td>
                      <td style={{ textAlign: 'center' }}>议程</td>
                    </tr>
                    <tr>
                      <td className='td-center'>11月11日</td>
                      <td className='td-padding'>注册报到</td>
                    </tr>
                    <tr>
                      <td className='td-center'>11月12日</td>
                      <td className='td-padding'>主论坛，6个专题论坛</td>
                    </tr>
                    <tr>
                      <td className='td-center'>11月13日</td>
                      <td className='td-padding'>主论坛，配套活动；博览会；大赛</td>
                    </tr>
                    <tr>
                      <td className='td-center'>11月14日</td>
                      <td className='td-padding'>主论坛，5个专题论坛；博览会；大赛</td>
                    </tr>

                  </table>
                </div>


              </div>
              <div className='conference-top-right'>
                <img src={wenta} />
              </div>
            </div>

            <div className='conference-bottom'>
              <div className='conference-bottom-right'>
                <ul>
                  <li><button id='plenary' onClick={this.plenary}>大会报告</button></li>
                  <li><button id='forum' onClick={this.forum}>分论坛</button></li>
                  <li><button id='activity' onClick={this.activity}>会员日活动</button></li>
                </ul>
              </div>
              <div className='conference-bottom-left'>
                <div id='plenarys'>大会</div>
                <div id='forums'>
                  <SubForum/>
                </div>
                <div id='activitys'>活动</div>
              </div>
              {/* <Menus/> */}
              {/* <ConferenceRoutes/> */}


            </div>

          </div>
          <Footer />
        </div>
      </div>

    )
  }
}

export default withRouter(Conference)