/* 合作伙伴 */
import React, { Component } from 'react'
import bjlg from '../../assets/image/beiligonglogo.jpg'
import fsxy from '../../assets/image/foshanxueyuanlogo.jpg'
import gdgs from '../../assets/image/guangdonggenshulogo.jpg'
class Cooperation extends Component {


  render() {
    return (
      <div className='cooperation-body'>
        <div className='cooperation'>
          <h1>合作伙伴</h1>
          <p>cooperative partner</p>
          <div className='div-img'>
            <img src={bjlg} />
          </div>
          <div className='div-img'>
            <img src={fsxy} />
          </div>
          <div className='div-img'>
            <img src={gdgs} />
          </div>
          <div className='div-img'>
            <img src={bjlg} />
          </div>
          <div className='div-img'>
            <img src={fsxy} />
          </div>
          <div className='div-img'>
            <img src={gdgs} />
          </div>

        </div>



      </div>

    )
  }
}
export default Cooperation