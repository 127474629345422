import React, { Component, Suspense } from 'react'
import { withRouter } from 'react-router';
import './home.scss'

import LifeElectronic from '../../component/home/LifeElectronic'//学会
import AnnualMeeting from '../../component/home/AnnualMeeting'//年会
import Report from '../../component/home/Report'//大会报告
import Forum from '../../component/home/Forum'//论坛版块
import Retrospect from '../../component/home/Retrospect'//往期回顾
import Spaceflight from '../../component/home/Spaceflight'//载荷空间
import Cooperation from '../../component/home/Cooperation'//合作伙伴
import Footer from '../../component/footer/Footer';//底部
import bannerTop from '../../assets/image/banner-top.png'
import bannerBottom from '../../assets/image/banner-bottom.png'
// const LifeElectronic = React.lazy(() => import('../../component/home/LifeElectronic'));
class Home extends Component {

  render() {
    // console.log(this.props);
    return (
      <div className='breadth'>

        <div className='homebanner'>

          {/* <img width='100%' height='100%' src={banner}/> */}
          <ul>
            <li>
               <img className='bannerTop' src={bannerTop} />
            </li>
            <li>
               <img className='bannerBottom' src={bannerBottom} />
            </li>
          </ul>
         
         
          <div className='banner-div'><a target='_blank' href='http://2022elife.meeting.cie-info.org.cn/'>我要参会</a></div>

        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <section>
            <div className='bj'>
              <LifeElectronic />
              <AnnualMeeting />
              {/* <Report /> */}
              <Forum />
              <Retrospect />
              <Spaceflight />
              <Cooperation />
              <Footer />
            </div>

          </section>
        </Suspense>

      </div>
    )
  }
}
export default withRouter(Home)
