/* 论坛 */
import medical_sensing from '../assets/icon/medical_sensing.svg'
import biomedical_computing_bioinformatics from '../assets/icon/biomedical_computing_bioinformatics.svg'
import screenage from '../assets/icon/screenage.svg'
import biomedical_testing from '../assets/icon/biomedical_testing.svg'
import electrophysiological_tutelage from '../assets/icon/electrophysiological_tutelage.svg'
import rkt_directed_energy_therapy from '../assets/icon/rkt_directed_energy_therapy.svg'
import space_biomedicine from '../assets/icon/space_biomedicine.svg'
import biochip_poct from '../assets/icon/biochip_poct.svg'
import smart_medicine_medical_iot from '../assets/icon/smart_medicine_medical_iot.svg'
import mhealth_chronic from '../assets/icon/mhealth_chronic.svg'
import life_electron from '../assets/icon/life_electron.svg'
import hefuchu from '../assets/image/hefuchu.png'
import zhangyukui from '../assets/image/zhangyukui.png'
import tangbenzhong from '../assets/image/tangbenzhong.png'
/* 
1、医学传感技术 Medical sensing technology
2、生物医学计算与生物信息学 Biomedical Computing and Bioinformatics
3、影像技术 Imaging technology
4、生物医学检测技术 Biomedical detection technology
5、电生理诊断与监护技术 Electrophysiological diagnosis and monitoring technology
6、放射治疗与定向能量治疗技术   Radiotherapy and directed energy therapy  RKT(放射治疗)
7、空间生物医学工程  Space Biomedical Engineering
8、生物芯片与POCT技术   Biochip and POCT Technology
9、智慧医疗与医疗物联网技术 Smart medicine and medical Internet of things technology
10、移动健康与慢病管理  Mobile health and chronic disease management
11、生命电子产业论坛    Life Electronics Industry Forum
*/
const forumcarousel = [
    {
        img: medical_sensing,
        title: '医学传感技术',
        path:'/conference/medical_sensing',
        personage:'刘志成',
        personage2:'谢曦',
        introduce:'生物医学传感技术是有关生物医学信息获取的技术，也是生物医学工程技术中的一个先导和核心技术，它与生物力学、生物材料、人体生理、生物医学电子与医疗仪器、信号与图像处理等其他生物医学工程技术直接相关．并是这些技术领域研究中共性的基础和应用研究内容。生物医学传感技术的创新和应用的进展直接关系到医疗器械，尤其是新型诊断及治疗仪器的水平，因此，国际上将该技术的研究与推动放在非常重要的地位。',
        conference:'会议议程',
        moderator:'会议主持人：刘志成、谢曦',
        speaker:'报告人简介',
        lecturer:[
            {
                id:'1',
                name:'张玉奎',
                title:'中国科学院院士',
                introduce:'近年来，根据分析化学和国际前沿研究领域的发展趋势，结合国家重大应用领域的需求，放眼于分析学科与生命学科的交叉发展，开展蛋白质高效分离与高灵敏检测的研究。发表论文500余篇，撰写专著7部，申请30余项发明专利。曾获中国科学院科技成果奖、辽宁省科技成果一等奖、辽宁省自然科学奖一等奖、国家自然科学奖二等奖、色谱终生成就奖、中国色谱贡献奖。',
                img:zhangyukui
            },
            {
                id:'2',
                name:'唐本忠',
                title:'中国科学院院士',
                introduce:'中国科学院院士，香港科技大学张鉴泉理学教授，化学系讲座教授。主要从事高分子合成方法论、先进功能材料开发和聚集诱导发光（AIE）现象的研究，连续多年当选全球材料和化学领域“高被引科学家”。凭借在"聚集诱导发光"领域的开创性贡献，唐本忠团队于2017年获得国家自然科学奖一等奖。',
                img:tangbenzhong
            },
        ],
        das: [
            {
               place:'北京',
               time:'2022-11-14',
               topic:'医学传感技术',
               lecturer:'5555'
            },
            {
              place:'北京',
              time:'2022-11-14',
              topic:'医学传感技术',
              lecturer:'666'
            },
            {
              place:'北京',
              time:'2022-11-14',
              topic:'医学传感技术',
              lecturer:'777'
            },
        ],
      
    },
    {
        img:biomedical_computing_bioinformatics,
        title: '生物医学计算与生物信息学',
         path:'/conference/biomedical_computing_bioinformatics',
        personage:'钱志余',
        personage2:'李金',
        introduce:'生物医学传感技术是有关生物医学信息获取的技术，也是生物医学工程技术中的一个先导和核心技术，它与生物力学、生物材料、人体生理、生物医学电子与医疗仪器、信号与图像处理等其他生物医学工程技术直接相关．并是这些技术领域研究中共性的基础和应用研究内容。生物医学传感技术的创新和应用的进展直接关系到医疗器械，尤其是新型诊断及治疗仪器的水平，因此，国际上将该技术的研究与推动放在非常重要的地位。',
        conference:'会议议程',
        moderator:'会议主持人：刘志成、谢曦',
        speaker:'报告人简介',
        lecturer:[
            {
                id:'1',
                name:'张玉奎',
                title:'中国科学院院士',
                introduce:'近年来，根据分析化学和国际前沿研究领域的发展趋势，结合国家重大应用领域的需求，放眼于分析学科与生命学科的交叉发展，开展蛋白质高效分离与高灵敏检测的研究。发表论文500余篇，撰写专著7部，申请30余项发明专利。曾获中国科学院科技成果奖、辽宁省科技成果一等奖、辽宁省自然科学奖一等奖、国家自然科学奖二等奖、色谱终生成就奖、中国色谱贡献奖。',
                img:zhangyukui
            },
            {
                id:'2',
                name:'唐本忠',
                title:'中国科学院院士',
                introduce:'中国科学院院士，香港科技大学张鉴泉理学教授，化学系讲座教授。主要从事高分子合成方法论、先进功能材料开发和聚集诱导发光（AIE）现象的研究，连续多年当选全球材料和化学领域“高被引科学家”。凭借在"聚集诱导发光"领域的开创性贡献，唐本忠团队于2017年获得国家自然科学奖一等奖。',
                img:tangbenzhong
            },
        ],
        das: [
            {
               place:'北京',
               time:'2022-11-14',
               topic:'医学传感技术',
               lecturer:'5555'
            },
            {
              place:'北京',
              time:'2022-11-14',
              topic:'医学传感技术',
              lecturer:'666'
            },
            {
              place:'北京',
              time:'2022-11-14',
              topic:'医学传感技术',
              lecturer:'777'
            },
        ],
        
        
    },
    {
        img: screenage,
        title: '影像技术',
         path:'/conference/screenage',
        personage:'顾月清',
        personage2:'宋志坚',
       
    },
    {
        img: biomedical_testing,
         path:'/conference/biomedical_testing',
        title: '生物医学检测技术',
        personage:'鄢盛恺',
        // personage2:'王学刚',
    },
    {
        img: electrophysiological_tutelage,
         path:'/conference/electrophysiological_tutelage',
        title: '电生理诊断与监护技术',
        personage:'张帅',
        personage2:'李婷',
    },
    {
        img: rkt_directed_energy_therapy,
         path:'/conference/rkt_directed_energy_therapy',
        title:'放射治疗与定向能量治疗技术',
        personage:'菅喜岐',
        personage2:'',
    },
    {
        img: space_biomedicine,
         path:'/conference/space_biomedicine',
        title: '空间生物医学工程',
        personage:'仓怀兴',
        personage2:'李晓琼',
       
    },
    {
        img: biochip_poct,
         path:'/conference/biochip_poct',
        title: '生物芯片与POCT技术',
        personage:'王东',
        personage2:'牟颖',
    },
    {
        img: smart_medicine_medical_iot,
         path:'/conference/smart_medicine_medical_iot',
        title: '智慧医疗与医疗物联网技术',
        personage:'邱学军',
        personage2:'高嵩',
    },
    {
        img: mhealth_chronic,
         path:'/conference/mhealth_chronic',
        title: '移动健康与慢病管理',
        personage:'唐晓英',
        personage2:'李舟',
    },
    {
        img: life_electron,
         path:'/conference/life_electron',
        title: '生命电子产业论坛',
        personage:'人物1',
        personage2:'人物2',
    },

]

export default forumcarousel