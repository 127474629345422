/* 参会人员 */
import dengyulin from '../assets/image/dengyulin.png'
import hefuchu from '../assets/image/hefuchu.png'
import zhangyukui from '../assets/image/zhangyukui.png'
import tangbenzhong from '../assets/image/tangbenzhong.png'
import tanweihong from '../assets/image/tanweihong.png'
import shaofeng from '../assets/image/shaofeng.png'
const delegates = [
    {   
        id:'1',
        name:'邓玉林',
        title:'国际宇航科学院院士',
        introduce:'北京理工大学医工融合研究院首席科学家、副院长，国际宇航科学院生命科学学部主席、中国电子学会生命电子学分会主任委员。瞄准“载人航天”和“深空探测”等国家重大科技工程在空间生命科学和航天医学领域的需求，针对由于空间环境的强制约与生物实验的高要求导致空间生命科学载荷在轨实验能力受限的现状，发展基于微流控芯片技术的在轨在线生命科学载荷技术。研制了多种技术领先的空间生命科学新型载荷并成功应用于多次航天飞行的科学任务。',
        img:dengyulin
    },
    {
        id:'2',
        /* 名字 */
        name:'贺福初',
        /* 头衔 */
        title:'中国科学院院士',
        /* 人物介绍 */
        introduce:'贺福初，中国科学院院士，蛋白质组学家。毕业于复旦大学生物系，军事医学科学院博士学位。少将军衔，研究员，专业技术一级，博士生导师。主要从事蛋白质组学和精准医学，生物信息学及系统生物学研究。',
        /* 照片 */
        img:hefuchu
    },
    {
        id:'3',
        name:'张玉奎',
        title:'中国科学院院士',
        introduce:'近年来，根据分析化学和国际前沿研究领域的发展趋势，结合国家重大应用领域的需求，放眼于分析学科与生命学科的交叉发展，开展蛋白质高效分离与高灵敏检测的研究。发表论文500余篇，撰写专著7部，申请30余项发明专利。曾获中国科学院科技成果奖、辽宁省科技成果一等奖、辽宁省自然科学奖一等奖、国家自然科学奖二等奖、色谱终生成就奖、中国色谱贡献奖。',
        img:zhangyukui
    },
    {
        id:'4',
        name:'唐本忠',
        title:'中国科学院院士',
        introduce:'中国科学院院士，香港科技大学张鉴泉理学教授，化学系讲座教授。主要从事高分子合成方法论、先进功能材料开发和聚集诱导发光（AIE）现象的研究，连续多年当选全球材料和化学领域“高被引科学家”。凭借在"聚集诱导发光"领域的开创性贡献，唐本忠团队于2017年获得国家自然科学奖一等奖。',
        img:tangbenzhong
    },
    {
        id:'5',
        name:'谭蔚泓',
        title:'中国科学院院士',
        introduce:'分析化学和化学生物学专家。中国科学院肿瘤与基础医学研究所所长，上海交通大学医学院分子医学研究院院长。他解决了分析化学与生物医学交叉领域中的一些关键科学问题，在国际生物分析化学领域有着重要的影响。他在核酸适体、分子识别、纳米生物传感等领域做了大量系统的原创性工作。提出核酸适体细胞筛选新方法，揭示其细胞识别的基本性质；提出多种高灵敏、高时空分辨纳米生物传感方法。',
        img:tanweihong
    },
    {
        id:'6',
        name:'邵峰',
        title:'中国科学院院士',
        introduce:'邵峰博士是天然免疫和细胞焦亡领域最为领先和贡献最大的国际科学领军者之一，在病原菌毒力机制、抗细菌天然免疫及抗肿瘤免疫等方面取得系列重要原创性发现。',
        img:shaofeng
    },
    {
        id:'7',
        name:'张玉奎',
        title:'中国科学院院士',
        introduce:'近年来，根据分析化学和国际前沿研究领域的发展趋势，结合国家重大应用领域的需求，放眼于分析学科与生命学科的交叉发展，开展蛋白质高效分离与高灵敏检测的研究。发表论文500余篇，撰写专著7部，申请30余项发明专利。曾获中国科学院科技成果奖、辽宁省科技成果一等奖、辽宁省自然科学奖一等奖、国家自然科学奖二等奖、色谱终生成就奖、中国色谱贡献奖。',
        img:zhangyukui
    },
    {
        id:'8',
        name:'唐本忠',
        title:'中国科学院院士',
        introduce:'中国科学院院士，香港科技大学张鉴泉理学教授，化学系讲座教授。主要从事高分子合成方法论、先进功能材料开发和聚集诱导发光（AIE）现象的研究，连续多年当选全球材料和化学领域“高被引科学家”。凭借在"聚集诱导发光"领域的开创性贡献，唐本忠团队于2017年获得国家自然科学奖一等奖。',
        img:tangbenzhong
    },
    {
        id:'9',
        name:'谭蔚泓',
        title:'中国科学院院士',
        introduce:'分析化学和化学生物学专家。中国科学院肿瘤与基础医学研究所所长，上海交通大学医学院分子医学研究院院长。他解决了分析化学与生物医学交叉领域中的一些关键科学问题，在国际生物分析化学领域有着重要的影响。他在核酸适体、分子识别、纳米生物传感等领域做了大量系统的原创性工作。提出核酸适体细胞筛选新方法，揭示其细胞识别的基本性质；提出多种高灵敏、高时空分辨纳米生物传感方法。',
        img:tanweihong
    },
    {
        id:'10',
        name:'邵峰',
        title:'中国科学院院士',
        introduce:'邵峰博士是天然免疫和细胞焦亡领域最为领先和贡献最大的国际科学领军者之一，在病原菌毒力机制、抗细菌天然免疫及抗肿瘤免疫等方面取得系列重要原创性发现。',
        img:shaofeng
    },
    {
        id:'11',
        name:'唐本忠',
        title:'中国科学院院士',
        introduce:'中国科学院院士，香港科技大学张鉴泉理学教授，化学系讲座教授。主要从事高分子合成方法论、先进功能材料开发和聚集诱导发光（AIE）现象的研究，连续多年当选全球材料和化学领域“高被引科学家”。凭借在"聚集诱导发光"领域的开创性贡献，唐本忠团队于2017年获得国家自然科学奖一等奖。',
        img:tangbenzhong
    },
    {
        id:'12',
        name:'邓玉林',
        title:'国际宇航科学院院士',
        introduce:'北京理工大学医工融合研究院首席科学家、副院长，国际宇航科学院生命科学学部主席、中国电子学会生命电子学分会主任委员。瞄准“载人航天”和“深空探测”等国家重大科技工程在空间生命科学和航天医学领域的需求，针对由于空间环境的强制约与生物实验的高要求导致空间生命科学载荷在轨实验能力受限的现状，发展基于微流控芯片技术的在轨在线生命科学载荷技术。研制了多种技术领先的空间生命科学新型载荷并成功应用于多次航天飞行的科学任务。',
        img:dengyulin
    },
    {
        id:'13',
        /* 名字 */
        name:'贺福初',
        /* 头衔 */
        title:'中国科学院院士',
        /* 人物介绍 */
        introduce:'贺福初，中国科学院院士，蛋白质组学家。毕业于复旦大学生物系，军事医学科学院博士学位。少将军衔，研究员，专业技术一级，博士生导师。主要从事蛋白质组学和精准医学，生物信息学及系统生物学研究。',
        /* 照片 */
        img:hefuchu
    },
    {
        id:'14',
        name:'张玉奎',
        title:'中国科学院院士',
        introduce:'近年来，根据分析化学和国际前沿研究领域的发展趋势，结合国家重大应用领域的需求，放眼于分析学科与生命学科的交叉发展，开展蛋白质高效分离与高灵敏检测的研究。发表论文500余篇，撰写专著7部，申请30余项发明专利。曾获中国科学院科技成果奖、辽宁省科技成果一等奖、辽宁省自然科学奖一等奖、国家自然科学奖二等奖、色谱终生成就奖、中国色谱贡献奖。',
        img:zhangyukui
    },
    
    
]
export default delegates