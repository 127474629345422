/* 载荷空间 */
import React, { Component } from 'react'
import './home.scss'

class Spaceflight extends Component {
  render() {
    return (
      <div className='spaceflight'>
        <h1>国际空间科学与载荷大赛</h1>
        <p>International Space Science and Scientific Payload Competition</p>
        <a target='_blank' href='http://isssp.bit.edu.cn/'>
          <button>我要参赛 </button>
        </a>

      </div>
    )
  }
}
export default Spaceflight