/* 论坛版块 */
import React, { Component } from 'react'
import './home.scss'
import forumcarousel from '../../common/forumcarousel'
import { Swiper, SwiperSlide } from "swiper/react";
import forums from '../../routes/forum'
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Link, NavLink } from 'react-router-dom';
import SwiperCore, { Autoplay, Sensitivity } from 'swiper/core'

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Grid, Navigation } from "swiper";
let routes = [].concat(
  forums
)
class Forum extends Component {
  click(item) {
    // console.log('论坛版块', item);
  }
  render() {
    return (
      <div className='forum-body'>
        <div className='forum'>
          <h1>论坛版块</h1>
          <p>Forum section</p>
          <div>
            <Swiper
              slidesPerView={4}//显示几个
              autoplay={{
                delay: 3000, // 默认延迟3s播放下一张
                stopOnLastSlide: false, // 播放到最后一张时停止：否
                disableOnInteraction: true // 用户操作轮播图后停止 ：是
              }}
              loop
            >
              {forumcarousel.map((item, index) => {
                return <SwiperSlide className='forum' >
                  {/* <Link target='_blank' activeClassName="light" to={item.path}> */}
                    <div className='forumdiv'>
                      <div className='forumimg'>
                        <img src={item.img} />
                      </div>

                      <p className='forump'>{item.title}</p>
                    </div>
                  {/* </Link> */}



                </SwiperSlide>

              })}
            </Swiper>



          </div>
        </div>
      </div>

    )
  }
}
export default Forum