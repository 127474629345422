/* 2022中国生命电子学术年会 */
import React, { Component } from 'react'
import Count from './count'
import wenta from '../../assets/image/wenta.png'
class AnnualMeeting extends Component {
  render() {
    return (
      <div className='annualMeeting'>
          {/* <h1>2022中国生命电子学术年会</h1> */}
          <div className='introduce-body'>
          <div className='introduce'>
            <div className='left'> 
              <img style={{width:'100%',height:'100%'}}  src={wenta}/>
            </div>
            <div className='right'>
                <div className='annualMeeting-p'>2022中国生命电子学术年会</div>
                <a target='_blank' href='http://2022elife.meeting.cie-info.org.cn/'>
                  <button style={{marginRight:'65px'}}>我要参会 </button>
                </a>


                <a target='_blank' href='http://paper.cie.org.cn/exh/register/registerexh?fair_id=31&business_role_id=Contributor'>
                  <button>投稿</button>
                </a>
                <Count/>
            </div>
          </div>

          </div>
         
      </div>
    )
  }
}
export default AnnualMeeting