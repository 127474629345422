import React, { Component } from 'react'
import './App.scss'
import routes from './routes/routes'

class App extends Component {
  render() {
    return (
      <div>
        {routes}
      </div>
    )
  }
}
export default App