import React, { Component } from 'react'
import Menus from './menus'
import header from '../../routes/header'
import Activity from '../../component/conference/Activity'
import Plenary from '../../component/conference/Plenary'

import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
let routes = [].concat(
  header
)
class conferenceRoutes extends Component {
  render() {
    return (
      <div style={{ height: '232px', width: '100%', background: '#ccc' }}>
        {/* <Menus /> */}
        {/* <div >
          <Switch>
            <Route exact path="/conference">
              <Redirect to='/conference' />
            </Route>
            {routes.map((navItem, index) => {
              if (navItem.children) {
                let arr = navItem.children.map((n, i) => {

                  return <Route key={'navI' + i} path={n.path} exact component={n.component} />
                })
                return arr

              }
            })}

          </Switch>
        </div> */}
        <div></div>
        <div></div>
      </div>
    )
  }
}
export default conferenceRoutes
