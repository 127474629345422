import React, { Component } from 'react'
import navList from '../../common/navList'
import { Link, NavLink } from 'react-router-dom';
import ConferenceRoutes from './conferenceRoutes'
class menus extends Component {
  render() {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          {navList.map((navItem, index) => {
            if (navItem.children) {
              let arr1 = navItem.children.map((n, i) => {
                // console.log(n.name);
                return <div style={{ width: 100, }}>
                  <NavLink to={n.path}>{n.name}</NavLink>
                </div>
              })
              return arr1

            }
          })}
        </div>
        <div>
          {/* <ConferenceRoutes/> */}
        </div>

      </div>

    )
  }
}
export default menus