import React, { Component } from 'react'
import './footer.scss'
import qrcode from '../../assets/image/dzwximg.png'
class Footer extends Component {
  render() {
    return (
      <div className='footer'>
        <div className='footer-top'>
          <div>
            <ul>
              <li>主办单位</li>
              <li>中国电子学会</li>
            </ul>
            <ul>
              <li>承办单位</li>
              <li>中国电子学会生命电子学分会</li>
              <li>广东省佛山市南海区人民政府</li>
              <li>北京理工大学生物医药成分分离与分析北京市重点实验室</li>
            </ul>
            <ul style={{ border: 'none' }}>
              <li>协办单位</li>
              <li>佛山科学技术学院</li>
              <li>理工亘舒（广东）科技有限公司</li>
            </ul>
            <div className='gzh'>
              <img src={qrcode} />
              <p>关注公众号</p>
            </div>

          </div>
        </div>
        <div className='footer-bottom'>
          <p>CopyRight @ 2017-2022 中国电子学会 All Rights Reserved <a href="https://beian.miit.gov.cn" target="_blank">京ICP备12041980号-2</a>
                          <a href="http://www.beian.gov.cn/" target="_blank">京公网安备110108003006号</a> </p>
        </div>

      </div>
    )
  }
}
export default Footer