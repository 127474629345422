import React, { Component } from 'react'
import navList from '../../common/navList'
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/image/logo2.svg'
import './menu.scss'
class menu extends Component {
  render() {
    return (
      <div style={{ position: 'fixed',zIndex:'99',width:"100%"}}>
        
        <div className='navlist'>
          <div>
             <img src={logo} style={{marginLeft:'300px'}} />
          </div>
          <div className='navlist'>
          {
            navList.map((navItem, index) => {
              return <div className='list'  key={'nav' + index}>
                
                <NavLink activeClassName="light"  to={navItem.path}>{navItem.name}</NavLink>
              </div>
            })
          }
          </div>
         
         

        </div>
        
      </div>
    )
  }
}
export default menu