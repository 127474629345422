import React, { Component } from 'react'
import './home.scss'
import learn from '../../assets/image/learn.png'
class LifeElectronic extends Component {

  cc() {


    if (document.body.offsetHeight > 168) {
      document.getElementById('introduce').style.opacity = '0'
    } else {
      console.log('cuowu');
    }
    console.log('可视', document.body.scrollHeight);
    console.log('div', document.getElementById('introduce').offsetHeight);


  }
  render() {
    return (
      <div className='lifeElectronic'  >
        <h2>中国生命电子学术年会</h2>
        <p>Binnnial Conference in Life Electronics,CIE</p>
        {/* <button onClick={this.cc}>keshi</button> */}

        <div className='lifeElectronic-div'>

          <div >
            <p className='introduce-p' id='introduce'>
              中国电子学会（Chinese Institute of Electronics）成立于1962年，现拥有个人会员10万余人，团体会员600多个，专业分会47个，专家委员会17个，工作委员会9个，编委会1个。中国电子学会总部是工业和信息化部直属事业单位，在职人员近200人。中国电子学会（含分支机构）是中国科协的重要组成部分，工作人员近5000人。26个省、自治区、直辖市设有地方学会组织。中国电子学会是5A级全国学术类社会团体。
            </p>
            <p className='introduce-p' id='introduce'>
            生命电子学分会成立于1988年11月23日，是中国电子学会的二级分会。支持单位为北京理工大学，秘书处现设立于北京理工大学生命学院。
            </p>
            <p className='introduce-p' id='introduce'>
              
              生命电子学分会一直致力于生命电子学的学术交流与推广，分会每年举办多种不同类型的学术活动，每两年举办一次具有重要学术影响的生命电子学术年会，邀请国内外顶级专家做大会报告和会议特邀报告，目前年会已成为国内生命电子学领域产学研用不同行业科技人员交流最新学术成果和了解国际学术动态的盛会。生命电子学分会重视科学普及工作，每年组织科普讲座、科普展览等，产生了良好的社会影响。
            </p>
          </div>
          <div >
            <img src={learn}></img>
          </div>
          {/* 
          <div >
            <p className='introduce-p' id='introduce' onChange={this.cc}>
              中国电子学会（Chinese Institute of Electronics）成立于1962年，现拥有个人会员10万余人，团体会员600多个，专业分会47个，专家委员会17个，工作委员会9个，编委会1个。中国电子学会总部是工业和信息化部直属事业单位，在职人员近200人。中国电子学会（含分支机构）是中国科协的重要组成部分，工作人员近5000人。26个省、自治区、直辖市设有地方学会组织。中国电子学会是5A级全国学术类社会团体。
            </p>
          </div> */}
        </div>
      </div>
    )
  }
}
export default LifeElectronic 