const navList = [
    {
        path: '/home',
        name: '首页'
    },
    {
        path: '/conference',
        name: '大会介绍',
        children: [
            {
                path:'/conference/plenary',
                name:'1.大会报告',
            },
            {
                path:'/conference/subforum',
                name:'2.分论坛',
            },
            {
                path:'/conference/activity',
                name:'3.会员日活动',
            },
        ]
    },
    {
        
        path: '/loadgame',
        // path:'',
        name: '载荷大赛'
    },
    {
        path: '/expo',
        name: '博览会'
    },
    {
        path: '/apply',
        name: '参会报名'
    },
    {
        path: '/download',
        name: '资料下载'
    },
    {
        path: '/roomboard',
        name: '住宿交通'
    },
    {
        path: '/contact',
        name: '联系我们'
    },

]
export default navList