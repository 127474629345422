/* 分论坛 */
import React, { Component } from 'react'
import forumcarousel from '../../common/forumcarousel'
import { Link, NavLink } from 'react-router-dom';
import './conference.scss'
class SubForum extends Component {
  render() {
    return (
      <div className='subforum'>
        {forumcarousel.map((item, index) => {
          return <div  >

            {/* <Link target='_blank' activeClassName="light" to={item.path}> */}
              <div className='subforum-div'>

                <p className='title'>{item.title}
                </p>
                <p className='subforum-p'>
                  <span className='personage'>主席：</span>
                  <span className='personage'>{item.personage}</span>
                  <span className='personage1'>{item.personage2}</span>
                </p>

              </div>
            {/* </Link> */}

          </div>
         
        })}
       

      </div>
    )
  }
}
export default SubForum