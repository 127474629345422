/* 大会报告 */
import React, { Component } from 'react'

 class Plenary extends Component {
  render() {
    return (
      <div>大会报告111</div>
    )
  }
}
export default Plenary