/* 往期回顾 */
import React, { Component } from 'react'
import CountUp from 'react-countup';
import a1 from '../../assets/img/a1.jpg'
import retrospec from '../../assets/image/retrospect.gif'//螺旋
import subject from '../../assets/image/subject.svg'//专题论坛
import convention from '../../assets/image/convention.svg'//大会报告
import guest from '../../assets/image/guest.svg'//特邀报告
import './home.scss'
class retrospect extends Component {
    render() {
        return (
            <div className='retrospect-boday'>
                 <div className='retrospect'>
                <div className='retrospect-top'>
                    <h1>往期回顾</h1>
                    <p>Previous review</p>
                </div>
                <div className='retrospect-bottom'>
                    <div className='retrospect-left'>
                        <div className='retrospect-number'>
                            <img src={subject} />
                            <p>专题论坛</p>
                            <CountUp className='count' start={0} end={16} /><span className='count'>+</span>
                        </div>
                        <div className='retrospect-number'>
                            <img src={convention} />
                            <p>大会报告</p>
                            <CountUp className='count' start={0} end={160} /><span className='count'>+</span>
                        </div>
                        <div className='retrospect-number'>
                            <img src={guest} />
                            <p>特邀报告</p>
                            <CountUp className='count' start={0} end={50} /><span className='count'>+</span>
                        </div>
                    </div>
                    <div className='retrospec'>
                        <img src={retrospec}/>
                    </div>
                </div>

            </div>

            </div>
           
        )
    }
}
export default retrospect