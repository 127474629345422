import Loadable from "@loadable/component";


const biochipPOCTTechnology = Loadable(() => import('../component/conference/biochipPOCTTechnology'));//生物芯片与POCT技术
const biomedicalcomputingBioinformatics = Loadable(() => import('../component/conference/biomedicalcomputingBioinformatics'));//生物医学计算与生物信息学
const biomedicalDetectionTechnology = Loadable(() => import('../component/conference/biomedicalDetectionTechnology'));//生物医学检测技术
const electrophysiologicalDiagnosisMonitoringTechnology = Loadable(() => import('../component/conference/electrophysiologicalDiagnosisMonitoringTechnology'));//电生理诊断与监护技术
const imagingTechnology = Loadable(() => import('../component/conference/imagingTechnology'));//影像技术
const lifeElectronicsIndustryForum = Loadable(() => import('../component/conference/lifeElectronicsIndustryForum'));//生命电子产业论坛
const medicalsensingtechnology = Loadable(() => import('../component/conference/medicalsensingtechnology'));//医学传感技术
const mobileHealthChronicDiseaseManagement = Loadable(() => import('../component/conference/mobileHealthChronicDiseaseManagement'));//移动健康与慢病管理
const radiotherapyDirectedEnergyTherapy = Loadable(() => import('../component/conference/radiotherapyDirectedEnergyTherapy'));//放射治疗与定向能量治疗技术
const smartMedicineMedicalInternetOfThingsTechnology = Loadable(() => import('../component/conference/smartMedicineMedicalInternetOfThingsTechnology'));//智慧医疗与医疗物联网技术
const spaceBiomedicalEngineering = Loadable(() => import('../component/conference/spaceBiomedicalEngineering'));//空间生物医学工程


const forum = [

    {
         path:'/conference/biochip_poct',
        component: biochipPOCTTechnology,//生物芯片与POCT技术
    },
    {
         path:'/conference/biomedical_computing_bioinformatics',
        component: biomedicalcomputingBioinformatics,//生物医学计算与生物信息学
    },
    {
         path:'/conference/biomedical_testing',
        component: biomedicalDetectionTechnology,//生物医学检测技术
    },
    {
         path:'/conference/electrophysiological_tutelage',
        component: electrophysiologicalDiagnosisMonitoringTechnology,//电生理诊断与监护技术
    },
    {
         path:'/conference/screenage',
        component: imagingTechnology,//影像技术
    },
    {
         path:'/conference/mhealth_chronic',
        component: mobileHealthChronicDiseaseManagement,//移动健康与慢病管理
    },
    {
         path:'/conference/life_electron',
        component: lifeElectronicsIndustryForum,////生命电子产业论坛
    },
    {
         path:'/conference/medical_sensing',
        component: medicalsensingtechnology,//医学传感技术
    },
    {
         path:'/conference/rkt_directed_energy_therapy',
        component: radiotherapyDirectedEnergyTherapy,//放射治疗与定向能量治疗技术
    },
    {
         path:'/conference/smart_medicine_medical_iot',
        component: smartMedicineMedicalInternetOfThingsTechnology,//智慧医疗与医疗物联网技术
    },
    {
         path:'/conference/space_biomedicine',
        component: spaceBiomedicalEngineering,//空间生物医学工程
    },
   
]
export default forum