import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {BrowserRouter,HashRouter} from 'react-router-dom'
import './index.scss'


ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    ,document.getElementById('root'));