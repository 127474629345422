/* 大会报告 */
import React, { Component } from 'react'
import delegates from '../../common/delegates'
import './home.scss'
import Lunbo from './lunbo'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Sensitivity } from 'swiper/core'

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Grid, Navigation } from "swiper";


SwiperCore.use([Autoplay])


class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person:[]
    }
  }

  personage=(index,item)=>{
    document.getElementById('personage').style.opacity = '1'
    document.getElementById("personages").style.opacity='0'
    // console.log(index);
    this.setState({
      person: index
     
  })
   
    
  }


  render() {
   

    return (
      <div className='report-body'>
         <div className='report'>
        <h1>大会报告</h1>
        <p>Plenary lecture</p>

        <Swiper id='personages' autoplay={{
          delay: 3000, // 默认延迟3s播放下一张
          stopOnLastSlide: false, // 播放到最后一张时停止：否
          disableOnInteraction: true // 用户操作轮播图后停止 ：是
          
        }}
          loop
        >
          {delegates.map((item, index) => {
            return <SwiperSlide className='report-top'>
              <div className='report-top-left' key={'report' + item.id}>

                <p className='name'>{item.name}</p>
                <p className='title'>{item.title}</p>
                <p className='introduce'>{item.introduce}</p>
              </div>
              <div className='report-top-right'>
                <img src={item.img} />
              </div>

            </SwiperSlide>

          })}
        </Swiper>


        <Swiper
          className='report-bottom mySwiper'

          grid={{
            fill: 'row',
            rows: 2,
          }}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Grid, Navigation]}
          slidesPerView={5}//显示几个
        // slidesPerColumn={2}//显示2行


        >
          {delegates.map((item, index) => {
            return <SwiperSlide key={'item' + item.id} onClick={this.personage.bind(index,item)} >
              <img style={{ width: '220px', height: '326px', margin: '40px 0 0 0px', }} src={item.img} 
                    
              />
            </SwiperSlide>
          })}
        </Swiper>
        <div className=' report-top' id='personage'>
          <div className='report-top-left' >

            <p className='name'>{this.state.person.name}</p>
            <p className='title'>{this.state.person.title}</p>
            <p className='introduce'>{this.state.person.introduce}</p>
          </div>
          <div className='report-top-right'>
            <img src={this.state.person.img} />
          </div>

        </div>

        {/* <Lunbo /> */}
      </div>

      </div>
     
    )
  }
}
export default Report