import Loadable from "@loadable/component";
const Home = Loadable(()=>import('../pages/Home/Home'));//首页
const Conference = Loadable(()=>import('../pages/Conference/Conference'));//大会介绍
const Plenary = Loadable(()=>import('../component/conference/Plenary'));//大会报告
const SubForum = Loadable(()=>import('../component/conference/SubForum'));//分论坛
const Activity = Loadable(()=>import('../component/conference/Activity'));//会员日活动
const LoadGame = Loadable(()=>import('../pages/LoadGame/LoadGame'));//载荷比赛
const Expo = Loadable(()=>import('../pages/Expo/Expo'));//博览会
const Apply = Loadable(()=>import('../pages/Apply/Apply'));//参会报名
const Download = Loadable(()=>import('../pages/Download/Download'));//资料下载
const RoomBoard = Loadable(()=>import('../pages/RoomBoard/RoomBoard'));//住宿交通
const Contact = Loadable(()=>import('../pages/Contact/Contact'));//联系我们


// import Home from '../pages/Home/Home'
// import Conference from '../pages/Conference/Conference'

const header = [
    {
        path: '/home',
        component: Home,
       
    },
    {
        path: '/conference',
        component: Conference,
        children: [
            {
                path:'/conference/plenary',
                component:Plenary,
            },
            {
                path:'/conference/subforum',
                component:SubForum,
            },
            {
                path:'/conference/activity',
                component:Activity,
            },
        ]
    },
    {
        path: '/loadgame',
        component: LoadGame,
       
    },
    {
        path: '/expo',
        component: Expo,
        
    },
    {
        path: '/apply',
        component: Apply,
        
    },
    {
        path: '/download',
        component: Download,
       
    },
    {
        path: '/roomboard',
        component: RoomBoard,
        
    },
    {
        path: '/contact',
        component: Contact,
        
    },
]

export default header