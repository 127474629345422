import React, { Component } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Sensitivity,} from 'swiper/core'

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Grid, Pagination,Navigation } from "swiper";
import delegates from '../../common/delegates'
SwiperCore.use([Autoplay])
class lunbo extends Component {
    render() {
        return (
            <div>
                <Swiper
                slidesPerView={5}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Grid,Navigation]}
                className="mySwiper"

                    grid={{
                        fill: 'row',
                        rows: 2,
                    }}
                    // spaceBetween={10}
                    // pagination={{
                    //     clickable: true,
                    // }}
                   
                    // loop={true}
                    
                    // navigation={true}
                    

                    // modules={[Grid, Pagination,Navigation]}
                    // className="mySwiper"
                >
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 1</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 2</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 3</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 4</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 5</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 6</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 7</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 8</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 9</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 7</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 8</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 9</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 81</SwiperSlide>
                    <SwiperSlide style={{ border: '1px solid #000', width: 400, height: 100 }}>Slide 9</SwiperSlide>
                </Swiper>
            </div>
        )
    }
}
export default lunbo