/* 会员日活动 */
import React, { Component } from 'react'
import { withRouter } from 'react-router';
class Activity extends Component {
  render() {
    return (
      <div>会员日活动11</div>
    )
  }
}
export default withRouter( Activity)