import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import header from "./header";
import forum from "./forum";
import Menu from '../component/menu/menu'
import ConferenceRoutes from '../component/conference/conferenceRoutes'
import Home from '../pages/Home/Home'
import Conference from '../pages/Conference/Conference'


let routes = [].concat(
    header
)
let routess = [].concat(
    forum
)

export default (
    <div>

        <Menu />
        <div>
            <Switch>
                <Route exact path="/">
                    <Redirect to='/home' />
                </Route>
                

                {
                    routes.map((item, index) => {

                        return <Route key={'nav' + index} path={item.path} exact component={item.component} />
                    })
                }
                {
                    routess.map((item, index) => {

                        return <Route key={'navv' + index} path={item.path} exact component={item.component} />
                    })
                }

            </Switch>
            {/* <ConferenceRoutes/> */}
        </div>

    </div>




)